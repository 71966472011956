export const codes = [
  {
    value: '+54',
    text: '+54 (Argentina)'
  },
  {
    value: '+591',
    text: '+591 (Bolivia)'
  },
  {
    value: '+57',
    text: '+57 (Colombia)'
  },
  {
    value: '+506',
    text: '+506 (Costa Rica)'
  },
  {
    value: '+56',
    text: '+56 (Chile)'
  },
  {
    value: '+593',
    text: '+593 (Ecuador)'
  },
  {
    value: '+502',
    text: '+502 (Guatemala)'
  },
  {
    value: '+52',
    text: '+52 (México)'
  },
  {
    value: '+51',
    text: '+51 (Perú)'
  },
  {
    value: '+1',
    text: '+1 (United States)'
  },
  {
    value: '',
    text: 'Otro'
  }
]
