export const countries = [
  'Argentina',
  'Bolivia',
  'Colombia',
  'Costa Rica',
  'Chile',
  'Ecuador',
  'Guatemala',
  'México',
  'Perú',
  'USA',
  'Otro'
]
