<template>
  <div class="divForm">
    <div class="divInterno">
      <div class="modalCard" v-if="modal">
        <img
        src="@/assets/images/modal.png"
        alt="modal"
        width="250px"
        height="auto"
        />
      </div>
      <div>
        <div style="display: flex; justify-content: center">
          <h1>Contáctanos</h1>
        </div>
        <div style="display: flex; justify-content: center">
          <img
          :src="require(`@/assets/images/step${pasoActual}.png`)"
          class="imgSteps"
          draggable="false"
          alt="steps"
          />
        </div>
      </div>
      <div v-if="pasoActual === 2">
        <v-text-field
        label="Nombre *"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        v-model="formData.firstName"
        />
        <v-text-field
        label="Apellido *"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        v-model="formData.lastName"
        />
        <v-text-field
        label="Correo Electrónico *"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        :rules="emailValidation"
        v-model="formData.email"
        />
        <div style="display: flex;">
          <v-select
          style="width: 40%; margin-right: 10px"
          label="Código país *"
          class="textField"
          :items="countriesCodes"
          hide-details="auto"
          outlined
          v-model="formData.countryCode"
          />
          <v-text-field
          label="Número Telefónico *"
          type="number"
          class="textField"
          hide-details="auto"
          outlined
          clearable
          v-model="formData.phone"
          />
        </div>
      </div>
      <div v-else-if="pasoActual === 3">
        <v-text-field
        v-if="otherCountrySelected"
        label="Ingresar país *"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        v-model="formData.country"
        append-icon="mdi-undo"
        @click:append="otherCountrySelected = false"
        />
        <v-select
        v-else
        label="Elegir País *"
        class="textField"
        :items="countries"
        hide-details="auto"
        outlined
        clearable
        v-model="formData.country"
        @change="enableOtherCountryInput"
        />
        <v-text-field
        label="Correo Corporativo"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        :rules="emailValidation"
        v-model="formData.businessEmail"
        />
        <v-text-field
        label="Empresa"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        v-model="formData.business"
        />
        <v-text-field
        label="Cargo"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        v-model="formData.position"
        />
        <v-text-field
        v-if="otherSolutionSelected"
        label="Ingresar Solución *"
        class="textField"
        hide-details="auto"
        outlined
        clearable
        v-model="formData.solution"
        append-icon="mdi-undo"
        @click:append="otherSolutionSelected = false"
        />
        <v-select
        v-else
        label="¿Qué necesita solucionar? *"
        class="textField"
        :items="solutions"
        hide-details="auto"
        clearable
        outlined
        v-model="formData.solution"
        @change="enableOtherSolutionInput"
        />
      </div>
      <div style="display: flex; justify-content: center;">
        <div
        v-if="pasoActual === 1"
        style="width: 100%; display: flex; justify-content: space-evenly; flex-wrap: wrap;"
        >
          <v-btn
          class="btn"
          style="height: 3rem; width: 100%; margin: .3rem"
          color="primary"
          @click="agendarCita">Agendar una Cita</v-btn>
          <v-btn
          class="btn"
          style="height: 3rem; width: 100%; margin:.3rem"
          color="primary"
          @click="nextStep">Quiero que me Contacten</v-btn>
        </div>
        <div
        v-else-if="pasoActual === 2"
        style="width: 100%; display: flex; justify-content: space-between;"
        >
          <v-btn
          class="btn"
          style="width: 49%; height: 3rem;"
          color="primary"
          @click="backStep">Atrás</v-btn>
          <v-btn
          class="btn"
          style="width: 49%; height: 3rem;"
          color="primary"
          @click="nextStep"
          :disabled="isNextDisabled">Siguiente</v-btn>
        </div>
        <div
        v-else-if="pasoActual === 3"
        style="width: 100%; display: flex; justify-content: space-between;"
        >
          <v-btn
          class="btn"
          style="width: 49%; height: 3rem;"
          color="primary"
          @click="backStep">Atrás</v-btn>
          <v-btn
          class="btn"
          style="width: 49%; height: 3rem;"
          color="primary"
          @click="createHubspotLead"
          :loading="loadingHubspotCall"
          :disabled="isSendDisabled || loadingHubspotCall">Enviar</v-btn>
        </div>
      </div>
      <div
      v-if="pasoActual === 1"
      style="display:flex; justify-content: center"
      >
        <img
        src="@/assets/images/logo.png"
        style="width:120px; margin-top: 6rem;"
        alt="ba360"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { countries } from './countriesList'
import { codes } from './countriesCodes'
import { solutions } from './solutionsList'
export default {
  name: 'Form',
  data: () => {
    return {
      emailValidation: [
        value => value.length ? /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) : null
      ],
      otherCountrySelected: false,
      otherSolutionSelected: false,
      loadingHubspotCall: false,
      pasoActual: 1,
      modal: false,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '',
        phone: '',
        country: '',
        businessEmail: '',
        business: '',
        position: '',
        solution: ''
      },
      hubspotCalendar: 'https://meetings.hubspot.com/wilter',
      successPage: 'https://www.beaware360.com/start-up/contacto-exitoso/',
      countries: countries,
      countriesCodes: codes,
      solutions: solutions
    }
  },
  computed: {
    isNextDisabled () {
      return !this.formData.firstName || !this.formData.lastName || !(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.formData.email)) || !this.formData.phone
    },
    isSendDisabled () {
      return !this.formData.country || !this.formData.solution
    }
  },
  mounted () {
    window.fbq('track', 'Form')
  },
  methods: {
    enableOtherCountryInput (country) {
      if (country === 'Otro') {
        this.otherCountrySelected = true
        this.formData.country = ''
      }
    },
    enableOtherSolutionInput (solution) {
      if (solution === 'Otro') {
        this.otherSolutionSelected = true
        this.formData.solution = ''
      }
    },
    createHubspotLead () {
      window.fbq('track', 'Create Hubspot Lead')
      this.loadingHubspotCall = true
      const data = {
        nombreNegocio: this.formData.business ? `${this.formData.business} - Be Aware 360 Start Up` : `${this.formData.firstName} ${this.formData.lastName} - Be Aware 360 Start Up`,
        nombreContacto: this.formData.firstName,
        apellido: this.formData.lastName,
        telefono: this.formData.countryCode + this.formData.phone,
        correo: this.formData.email,
        pais: this.formData.country,
        correoCorporativo: this.formData.businessEmail,
        cargo: this.formData.position,
        necesidad: this.formData.solution
      }
      const url = 'https://apiserv.beaware360.com/LeadSync'
      axios.post(url, data)
        .then(response => {
          let result = response.data
          if (typeof result === 'string') {
            result = JSON.parse(result)
            if (result.codigo === 0) {
              window.open(this.successPage, '_top')
            } else {
              this.modal = true
              setTimeout(() => {
                this.modal = false
              }, 3500)
            }
          }
        }).catch(() => {
          this.modal = true
          setTimeout(() => {
            this.modal = false
          }, 3500)
        })
      this.loadingHubspotCall = false
    },
    nextStep () {
      this.pasoActual++
    },
    backStep () {
      this.pasoActual--
    },
    agendarCita () {
      window.fbq('track', 'Agendar Cita')
      window.open(this.hubspotCalendar, '_self')
    }
  }
}
</script>

<style scoped>
.divForm {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 1.5rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.divInterno {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 532px;
}

h1 {
  font-weight: 400;
  letter-spacing: .2px;
  color: #004750;
  line-height: 1.2;
}

.imgSteps {
  width: 98%;
  margin: 2rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: none;
  pointer-events: none;
}

.textField {
  height: 4rem;
  width: 100%;
}

.btn {
  font-weight: 600;
}
.modalCard {
  position: absolute;
  width: 250px;
  height: auto;
  outline: 2px solid #C32905;
  background: #fff;
  z-index: 3;
}

/* esto de abajo es para quitar las flechas del input number */
.textField>>>input[type="number"] {
  -moz-appearance: textfield;
}
.textField>>>input[type="number"]::-webkit-outer-spin-button,
.textField>>>input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
/* esto de arriba es para quitar las flechas del input number */
</style>
