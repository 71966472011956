<template>
  <v-app class="app">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
        </v-col>
      </v-row>
      <v-row>
        <Form />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Form from '../components/Form'

export default {
  name: 'CreateAutoaprov',

  components: {
    Form
  }
}
</script>

<style scoped>
  .app {
    background: #fff !important;
  }
</style>
